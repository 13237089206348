import { createGlobalStyle } from 'styled-components';
import PropaneSVGBackground from '../images/the-propane-home-bg-en.jpg';
import HeleveticNeue from '../../static/fonts/HelveticaNeue-Light.woff';
import HeleveticNeueWoff2 from '../../static/fonts/HelveticaNeue-Light.woff2';
import HeleveticNeueBold from '../../static/fonts/HelveticaNeue-Bold.woff';
import HeleveticNeueBoldWoff from '../../static/fonts/HelveticaNeue-Bold.woff2';
import HeleveticNeueRoman from '../../static/fonts/HelveticaNeue-Roman.woff';
import HeleveticNeueRomanWoff from '../../static/fonts/HelveticaNeue-Roman.woff2';
import GothamBlack from '../../static/fonts/Gotham-Black.woff';
import GothamBlack2 from '../../static/fonts/Gotham-Black.woff2';
import GothamBold from '../../static/fonts/Gotham-Bold.woff';
import GothamBoldSvg from '../../static/fonts/gothambold.svg#gothambold';
import GothamBoldEot from '../../static/fonts/gothambold.eot';
import GothamBoldTtf from '../../static/fonts/gothambold.ttf';
import GothamBook from '../../static/fonts/Gotham-Book.woff';
import GothamBookSvg from '../../static/fonts/gothambook.svg#gothambook';
import GothamBookEot from '../../static/fonts/gothambook.eot';
import GothamBookTtf from '../../static/fonts/gothambook.ttf';
import GothamLight from '../../static/fonts/Gotham-Light.woff';
import GothamLightEot from '../../static/fonts/gothamlight.eot';
import GothamLightSvg from '../../static/fonts/gothamlight.svg#gothamlight';
import GothamLightTtf from '../../static/fonts/gothamlight.ttf';
import GothamMedium from '../../static/fonts/Gotham-Medium.woff';
import GothamMediumSvg from '../../static/fonts/gothammedium.svg#gothammedium';
import GothamMediumEot from '../../static/fonts/gothammedium.eot';
import GothamMediumTtf from '../../static/fonts/gothammedium.ttf';

const SlpGlobalStyles = createGlobalStyle`
/* This is used to test if the stylesheet has been loaded yet*/
@font-face {
    font-family: 'Helvetica Neue';
    src: url(${HeleveticNeueWoff2}) format('woff2'),
    url(${HeleveticNeueBold}) format('woff'),
    url(${HeleveticNeueBoldWoff}) format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
  font-family: "Gotham-Black";
  src: url(${GothamBlack2}) format('woff2'),
  url(${GothamBlack}) format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Gotham-Book";
  src: url(${GothamBook}) format('woff'),
  url(${GothamBookEot}),
    url(${GothamBookTtf}) format('truetype'),
    url(${GothamBookSvg}) format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham-Light";
  src: url(${GothamLight}) format('woff'),
  	url(${GothamLightEot}),
	url(${GothamLightTtf}) format('truetype'),
    url(${GothamLightSvg}) format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Gotham-Medium";
  src: url(${GothamMedium}) format('woff'),
  	url(${GothamMediumEot}),
    url(${GothamMediumTtf}) format('truetype'),
    url(${GothamMediumSvg}) format('svg');
font-weight: normal;
font-style: normal;
}
@font-face {
  font-family: "Gotham-Bold";
  src: url(${GothamBold}) format('woff'),
  url(${GothamBoldEot}),
  url(${GothamBoldTtf}) format('truetype'),
  url(${GothamBoldSvg}) format('svg');
  font-weight: bold;
  font-style: normal;
}
.propaneHome{
	.st0{fill:#3A2417;}
	.st1{fill:#FFFFFF;}
	.st2{fill: #00493B;}
	.svgTextContainer{
		:hover{
			cursor: pointer;
			.st2{fill: #FFC429;};
			}
	}
	svg{
		display: block;
		top: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: auto;
		margin: 0 auto;
		background: url(${PropaneSVGBackground}) no-repeat center left;
		background-size: 100% auto;
		:focus{
			outline: none;
		}
	
		@media (max-width: 767px){
			width: 100%;
			position: static;
		}
	}
}

@media (max-width: 767px){
	.tooltip-furnace-boiler{
		transform: translate(-20%, -40%);
	}
	.tooltip-water-heater{
		transform: translate(5%,-30%);
	}
	.tooltip-bbq{
		transform: translate(-35%,-15%);	
	}
	.tooltip-generator{
		transform: translate(-30%, 25%);	
	}
	.tooltip-heat-pump{
		transform: translate(0%, -20%);
	}
	.tooltip-gas-range{
		transform: translate(-20%, -20%);
	}
}

.propaneHome-tooltip {
	/* width: 425px;
    padding: 40px;
	font-size: 16px;
    line-height: 22px; */
	width: 250px;
    font-size: 9px;
    line-height: 12px;
    padding: 20px;
    background: #00493B;
    border-radius: 6px;
	text-align: center;
	font-family: 'HelveticaNeue-Light', arial, sans-serif;
    margin-bottom: 0;
    color: #fff;
	@media (max-width: 767px){
		width: 425px;
    	font-size: 16px;
    	line-height: 22px;
	}
	@media (max-width: 575px){
		width: 425px;
    	font-size: 24px;
    	line-height: 26px;
	}
}
`;
export default SlpGlobalStyles;
